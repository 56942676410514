@media screen and (min-width: 641px) {
  .basic {
    display: flex;
    justify-content: space-around;
  }
}
@media screen and (min-width: 1008px) {
  .work {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
