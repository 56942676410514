.body {
  min-height: 100vh;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.body * {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  background: var(--white);
  border-radius: 30px;
  padding: 1rem;
  margin: 2rem 24rem;
  height: calc(100% - 19rem);
  flex-direction: column;
  gap: 2rem;
  /* background-color: var(--background-transparent-white); */
}

.side {
  flex: 1;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
.backdrop {
  /* position: fixed; */
  background: var(--primary-background-transparent);
  height: 100%;
  width: 100%;
  min-height: 100vh;
  /* padding-left: 13%;
  padding-right: 13%; */
}
@media screen and (max-width: 786px) {
  .backdrop {
    background: var(--primary-background-transparent);
    height: 100%;
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
    min-height: 100vh;
  }
}
.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
      max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: var(--label-black);
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: var(--primary-color);
}
.headerLabelText {
  text-align: start;
  color: var(--label-black);
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  padding: 2rem;
  background: var(--primary-background-transparent);
  border: 1px solid var(--primary-border);
  border-radius: 30px;
}

.holdOnTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  flex-direction: column;
}
.holdOnHelperText {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
}
.holdOnHelperTextSmall {
  font-size: 0.9rem;
  margin: 0;
  color: var(--primary-color);
  padding: 0;
  font-size: 1.2rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .container {
    margin: 2rem 1rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .container {
    margin: 2rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    margin: 2rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container {
    margin: 2rem 8rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .container {
    margin: 2rem 22rem;
  }
}
