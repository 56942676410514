.body {
    min-height: 100vh;
    //background: url("../../images/hero_bg.svg");
    //background:red;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  
  .container {
    display: flex;
    justify-content: center;
    align-items: start;
    /* height: 100%; */
    background: var(--white);
    
    border-radius: 10px;
    padding: 1rem;
    margin: 2rem;
    //margin: 2rem 24rem;
    height: calc(100% - 19rem);
    flex-direction: column;
    gap: 2rem;
    /* width: 50%; */
    /* background-color: var(--background-transparent-white); */
    overflow-y: auto;
  }


  .backdrop {
    /* position: fixed; */
    background: var(--primary-background-transparent);
    height: 100%;
    width: 100%;
    min-height: 100vh;
   
  }


  .content {
    width:100%;
  }

  @media only screen and (max-width: 600px){
    .bankdetailsContainer {
      display:flex;
      flex-direction:row;
    }
  }

  .verifyButton {
    color: #2c6ce3;
    border: 1px solid #2c6ce3;
    padding: 0.2rem 1rem;
    border-radius: 10px;
    background-color: #ffffff;
  }

  @media only screen and (max-width: 600px){
    
  }

  .labelText {
    font-weight: 600;
    font-size: 1rem;
    color: #000000;
    font-family: "Outfit-Medium";
    margin-bottom: 10px;
  }
  