.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.helpCenter {
  color: var(--primary-color);
  font-size: 1.2em;
  padding: 0.5rem 0;
}

.contactDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-left: 1em;
  margin-right: 1em;
  font-size: 1.3rem;
}

.contactItem {
  display: flex;
  gap: 0.4rem;
  align-self: center;
  justify-content: center;
}
@media screen and (max-width: 786px) {
  .contactDiv {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin-left: 1em;
    margin-right: 1em;
    font-size: 1.3rem;
  }
}

.copyrightText {
  /* padding: 2rem 0 1rem 0; */
  font-size: 1em;

  text-align: center;
}


/* modifications */

@media screen and (max-width: 600px) {
  .copyrightText {
    padding: 10px; 
    font-size: 0.7em;
    text-align: center;
  }
}

.contactNumber {
  font-size:1em;

}

@media screen and (max-width: 600px) {
  .contactNumber {
    font-size:0.5em;

  }
}

.PhoneIconImage {
  width: 1em;
  height: 1em;
   marginTop: 5px;
}


@media screen and (max-width: 600px){
.PhoneIconImage {
  width: 0.5em;
  height: 0.5em;
   marginTop: 6px;
}
}


.mailText{
  font-size:1em;
}

@media screen and (max-width: 600px) {
  .mailText{
    font-size:0.5em;
  }
}

.mailIconImage{
  width: 1em;
  height: 1em;
   marginTop: 5px;

}

@media screen and (max-width: 600px){
  .mailIconImage{
    width: 0.5em;
  height: 0.5em;
   marginTop: 6px;
  }
}

